import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import Container from 'react-bootstrap/Container';
import { PageTitle } from '../components/page/PageTitle';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const TermsAndConditionsPage = () => {
    return (
      <DefaultLayout>
        <PageTitle title={'Terms & Conditions'}/>
        <main className="breakaway-content">
          <Container>
            <StaticQuery
              query={/* language=GrapGQL */ graphql`
              query {
                Faq: file(relativePath: { eq: "sunrise.jpg" }) {
                  childImageSharp {
                  fluid(
                  maxWidth: 1100,
                  srcSetBreakpoints: [500, 700, 1020, 1200]
                  quality: 90
                  sizes: "(max-width: 4000px) 100vw, 2000px, (max-width: 1200px) 1200px, (max-width: 700px) 100vw, 700px, (max-width: 500px) 100vw, 500px"
                  ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
               }
            `}
              render={data => (  <div><Img className="breakaway-page-image" fluid={data.Faq.childImageSharp.fluid}/></div>  )}
            />
            <p className="lead">Membership applies from the date of purchase and is non-transferable in whole or part. Breakaway Club offers are subject to promotional rate availability and are distributed at the discretion of Potters Resorts.</p>

            <p>Breakaway Club members can bring up to 3 other guests on their booking, but the member must make the booking and be present on the reservation. Potters Resorts reserves the right to change/amend or withdraw offers at any time and without prior notice.</p>

            <p>All prices shown for Potters Resorts Hopton-on-Sea are per person and based on Bungalow accommodation; Bungalow Plus and Hotel supplements apply. All prices shown for Potters Resorts Five Lakes are per person and based on Premier accommodation; Premier Plus, Deluxe, Deluxe Plus and Hotel Suite supplements apply. When making a Breakaway Club booking, it is unlikely you will be allocated your preferred accommodation or room type, and special requests will not normally be fulfilled.</p>

            <p>Your specific Breakaway code must be quoted at time of booking. Breakaway Club offers apply to new bookings only and cannot be used in conjunction with any other offer, promotion or discount. Breakaway Club offers are published in their entirety to our full membership database. Communication does not take into account any existing bookings members may have made, as they may wish to book another Breakaway Club offer available within that communication. Existing bookings are not eligible for discount if later offered through the Breakaway Club and the total price will remain as quoted at time of booking. We ask that under no circumstance you request The Potter Family, Management or Team to waive or amend this condition of Breakaway Club.</p>

            <p>Breakaway Club bookings cannot be provisionally held and they are offered on a &ldquo;first to confirm and pay&rdquo; basis which is fair to all. Full payment must be taken at the time of booking.</p>

            <p>Breakaway Club’s 72 Hour Before Benefit of £100 per person, per night for your stay at Potters Resorts Hopton-on-Sea or Potters Resorts Five Lakes is available when booked within 72 hours of the break’s start date. Supplements apply for Bungalow Plus and Hotel accommodation at Hopton-on-Sea and Premier Plus, Deluxe, Deluxe Plus and Hotel Suite accommodation at Five Lakes. Subject to promotional rate availability.</p>

            <p>Breakaway Club’s SMS Notifications Benefit is only available for members who have opted-in to receive notifications, and with a correct mobile telephone number.</p>

            <p>For Potters Resorts&rsquo; booking terms and conditions, please visit <a href="https://www.pottersresorts.com/terms-and-conditions">https://www.pottersresorts.com/terms-and-conditions</a></p>

            <p>Calls are charged at national rate and in many cases count toward inclusive minutes on landline and mobile contracts.</p>
          </Container>
        </main>
      </DefaultLayout>
    );
}

export default TermsAndConditionsPage;
