import React from 'react';
import Container from 'react-bootstrap/Container';

const PageTitle = ({title}) => {
  return(
    <div className="breakaway-page-title">
      <Container>
        <h1>{title}</h1>
      </Container>
    </div>
  )
};

export {PageTitle}